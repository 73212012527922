import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const clientColumns = [
    {
        fieldName: 'name',
        name: 'Nom',
        type: Types.STRING,
    },
    {
        fieldName: 'contacts.0.lastname',
        name: 'Contacts',
        type: Types.STRING,
        // @ts-ignore
        onRender: item => Object.keys(item)
            .filter(x => !!x.match(/contacts\.\d+\.lastname/))
            ?.map((_, i) => {
                const content = (
                    <>
                        {item[`contacts.${i}.lastname`]}
                        {' '}
                        {item[`contacts.${i}.firstname`]}
                    </>
                )
                if (item[`contacts.${i}.hasLeft`])
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <del key={`${item[`contacts.${i}.contactId`]}_${i}`}>
                            <Link to={`/clients/${item.clientId}/contacts/${item[`contacts.${i}.contactId`]}`}>
                                {content}
                            </Link>
                        </del>
                    )
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={`${item[`contacts.${i}.contactId`]}_${i}`}>
                        <Link to={`/clients/${item.clientId}/contacts/${item[`contacts.${i}.contactId`]}`}>
                            {content}
                        </Link>
                    </React.Fragment>
                )
            })
            // @ts-ignore
            // eslint-disable-next-line react/no-array-index-key
            ?.reduce((prev, curr, currentIndex) => ([prev, prev ? <br key={`br-${currentIndex}`} /> : '', curr]), null)
        ,
    },
    {
        fieldName: 'contacts.0.metier.name',
        name: 'Métiers',
        type: Types.STRING,
        // @ts-ignore
        onRender: item => Object.keys(item)
            .filter(x => !!x.match(/contacts\.\d+\.metier.name/))
            ?.map((_, i) => {
                const content = item[`contacts.${i}.metier.name`]
                if (item[`contacts.${i}.hasLeft`])
                    // eslint-disable-next-line react/no-array-index-key
                    return (<del key={`${item[`contacts.${i}.contactId`]}_${i}`}>{content}</del>)
                // eslint-disable-next-line react/no-array-index-key
                return (<React.Fragment key={`${item[`contacts.${i}.contactId`]}_${i}`}>{content}</React.Fragment>)
            })
            // @ts-ignore
            // eslint-disable-next-line react/no-array-index-key
            ?.reduce((prev, curr, currentIndex) => ([prev, prev ? <br key={`br-${currentIndex}`} /> : '', curr]), null)
        ,
    },
    {
        fieldName: 'department.name',
        name: 'Département',
        type: Types.STRING,
    },
    {
        fieldName: 'turnover.name',
        name: 'CA',
        type: Types.STRING,
    },
    {
        fieldName: 'nature.name',
        name: 'Nature',
        type: Types.STRING,
    },
    {
        fieldName: 'missions.0.userCollaborator.lastname',
        name: 'Collaborateur',
        type: Types.STRING,
        onRender: item => [item['missions.0.userCollaborator.lastname'], item['missions.0.userCollaborator.firstname']].filter(x => x).join(' ') ?? '',
    },

]

export default clientColumns
