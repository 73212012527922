/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Candidate from 'requests/objects/candidate'
import FileCv from 'requests/objects/fileCv'
import CandidacyComment from 'requests/objects/boardComment'
import EBoardCardType from 'types/missions/enums/boardCardType'
import File from 'requests/objects/file'
import Interview from 'requests/objects/interview'
import Mission from 'requests/objects/mission'
import BoardColumn from 'requests/objects/boardColumn'

/**
 * Candidacy Object
 */
export default class Candidacy extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.candidacyId candidacyId of the Candidacy
     * @param {number=} data.missionId missionId of the mission
     * @param {object=} data.mission mission
     * @param {string=} data.origin origin
     * @param {boolean=} data.hasAgreed hasAgreed
     * @param {number=} data.candidateId candidateId
     * @param {object=} data.candidate candidate
     * @param {object=} data.fileCv fileCv
     * @param {number=} data.boardColumnId boardColumnId
     * @param {object=} data.boardColumn boardColumn
     * @param {boolean=} data.isRejected isRejected
     * @param {boolean=} data.isFavorite isFavorite
     * @param {boolean=} data.isCallDone isFavorite
     * @param {object[]=} data.boardComments boardComments
     * @param {string=} data.interviewStartDate interviewStartDate
     * @param {string=} data.interviewEndDate interviewEndDate
     * @param {string=} data.interviewClientStartDate interviewClientStartDate
     * @param {string=} data.interviewClientEndDate interviewClientEndDate
     * @param {object[]=} data.files files
     * @param {number=} data.candidacyProvenanceId candidacyProvenanceId
     * @param {object=} data.candidacyProvenance candacyProvenanceId
     * @param {string=} data.comment comment
     * @param {object=} data.interview interview
     * @param {object[]=} data.boardHistorics boardHistorics
     * @param {boolean=} data.isUnderProposal isUnderProposal
     * @param {boolean=} data.isInterviewCancel isInterviewCancel
     * @param {boolean=} data.isInterviewClientCancel isInterviewClientCancel
     * @param {string=} data.currentSalary currentSalary
     * @param {string=} data.desiredSalary desiredSalary
     * @param {boolean=} data.isDeleted isDeleted
     * @param {boolean=} data.isCancelled isDeleted
     */
    constructor({
        candidacyId = 0,
        missionId = 0,
        mission,
        origin = '',
        hasAgreed = true,
        candidateId = 0,
        candidate,
        fileCv,
        boardColumnId = 1,
        boardColumn = {},
        isRejected = false,
        isFavorite = false,
        isCallDone = false,
        boardComments = [],
        interviewStartDate,
        interviewEndDate,
        interviewClientStartDate,
        interviewClientEndDate,
        files = [],
        candidacyProvenanceId,
        candidacyProvenance = {},
        comment = '',
        interview = {},
        boardHistorics = [],
        isUnderProposal = false,
        isInterviewCancel = false,
        isInterviewClientCancel = false,
        currentSalary = '',
        desiredSalary = '',
        isDeleted = false,
        isCancelled = false,
        ...data
    } = {}) {
        super(data)
        this.candidacyId = candidacyId
        this.missionId = missionId
        this.mission = mission ? new Mission(mission) : undefined
        this.origin = origin
        this.hasAgreed = hasAgreed
        this.candidateId = candidateId
        this.candidate = new Candidate(candidate || {})
        this.fileCv = fileCv ? new FileCv(fileCv) : undefined
        this.boardColumnId = boardColumnId
        this.boardColumn = boardColumn
        this.isRejected = isRejected
        this.isCancelled = isCancelled
        this.isFavorite = isFavorite
        this.isCallDone = isCallDone
        this.boardComments = (boardComments?.map(x => new CandidacyComment(x)) ?? [])
            .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        this.interviewStartDate = interviewStartDate ? new Date(interviewStartDate) : undefined
        this.interviewEndDate = interviewEndDate ? new Date(interviewEndDate) : undefined
        this.interviewClientStartDate = interviewClientStartDate ? new Date(interviewClientStartDate) : undefined
        this.interviewClientEndDate = interviewClientEndDate ? new Date(interviewClientEndDate) : undefined
        this.files = files?.map(x => new File(x)) ?? []
        this.candidacyProvenanceId = candidacyProvenanceId
        this.candidacyProvenance = candidacyProvenance
        this.comment = comment
        this.interview = new Interview(interview || {})
        this.boardHistorics = boardHistorics?.map(x => new BoardColumn(x)) ?? []
        this.isUnderProposal = isUnderProposal
        this.isInterviewCancel = isInterviewCancel
        this.isInterviewClientCancel = isInterviewClientCancel

        this.currentSalary = currentSalary
        this.desiredSalary = desiredSalary
        this.isDeleted = isDeleted

        this.type = EBoardCardType.CANDIDACY
    }
}

/**
 * Candidacy Object used to bind error message
 */
export class ErrorCandidacy extends ErrorBase {
    constructor() {
        super()
        this.candidacyId = ''
        this.missionId = ''
        this.origin = ''
        this.hasAgreed = ''
        this.candidateId = ''
        this.candidate = {}
        this.isRejected = ''
        this.isCancelled = ''
        this.isFavorite = ''
        this.isCallDone = ''
        this.interviewStartDate = ''
        this.interviewEndDate = ''
        this.interviewClientStartDate = ''
        this.interviewClientEndDate = ''
        this.candidacyProvenanceId = ''
        this.candidacyProvenance = ''
        this.comment = ''
        this.interview = ''
        this.isUnderProposal = ''
        this.isInterviewCancel = ''
        this.isInterviewClientCancel = ''
        this.currentSalary = ''
        this.desiredSalary = ''
        this.isDeleted = ''
    }
}
