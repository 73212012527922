import React, {
    useCallback, useEffect, useRef,
} from 'react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import HandleBlob from 'helpers/methods/blob'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FileInput from 'components/inputs/fileInput'
import File from 'requests/objects/file'
import MissionsHandler from 'requests/handlers/missionsHandler'
import Mission from 'requests/objects/mission'

/**
 * Files list
 * @param {object} props Props
 * @param {string} props.label label
 * @param {keyof Mission} props.fieldKey fieldKey
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {MissionsHandler} props.handler MissionsHandler
 * @param {(file: File) => void} props.updateItem updateItem
 * @param {Mission} props.mission Mission
 * @param {string=} props.labelUpload labelUpload
 * @returns {JSX.Element} Returns
 */
export default function DocumentInput({
    label,
    fieldKey,
    isReadOnly = false,
    handler,
    updateItem = () => null,
    mission = new Mission(),
    labelUpload = 'Téléverser',
}) {
    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const handlerUploadFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const handlerRemoveFile = useRef(null)

    const download = useCallback(async () => {
        try {
            handlerGetFile.current = handler.getFile(mission.missionId, fieldKey)
            const blob = await handlerGetFile.current.fetch()
            HandleBlob.download(blob, mission?.[fieldKey]?.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [mission, handler, fieldKey])

    const upload = useCallback(
        /**
         * @param {globalThis.File} file file
         */
        async file => {
            try {
                handlerUploadFile.current = handler.uploadFile(mission.missionId, fieldKey, file)
                const missionFile = await handlerUploadFile.current.fetch()
                updateItem(missionFile)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [handler, updateItem, mission, fieldKey],
    )

    const remove = useCallback(async () => {
        try {
            handlerRemoveFile.current = handler.removeFile(mission.missionId, fieldKey)
            await handlerRemoveFile.current.fetch()
            updateItem(new File())
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [mission, handler, updateItem, fieldKey])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerGetFile?.current?.cancel()
        handlerUploadFile?.current?.cancel()
        handlerRemoveFile?.current?.cancel()
    }, [])

    return (
        <FileInput
            label={label}
            labelUpload={labelUpload}
            isReadOnly={isReadOnly}
            isDisabled={!mission.missionId}
            fileName={mission[fieldKey]?.name}
            tooltipContent=""
            onDownload={() => download()}
            // onOpen={() => download(true)}
            onUpload={file => upload(file)}
            onDelete={() => remove()}
        />
    )
}
