/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import BoardComment from 'requests/objects/boardComment'
import EBoardCardType from 'types/missions/enums/boardCardType'
import FileCv from 'requests/objects/fileCv'
import File from 'requests/objects/file'
import Candidate from 'requests/objects/candidate'
import Interview from 'requests/objects/interview'
import Mission from 'requests/objects/mission'
import BoardColumn from 'requests/objects/boardColumn'

/**
 * Hunt Object
 */
export default class Hunt extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.huntId huntId of the Hunt
     * @param {number=} data.missionId missionId of the mission
     * @param {object=} data.mission mission
     * @param {string=} data.company company
     * @param {string=} data.zipCode zipCode
     * @param {number=} data.cityId city
     * @param {object=} data.city city
     * @param {number=} data.departmentId departmentId
     * @param {object=} data.department department
     * @param {string=} data.landlinePhone landlinePhone
     * @param {string=} data.comment comment
     * @param {boolean=} data.isRejected isRejected
     * @param {boolean=} data.isFavorite isFavorite
     * @param {boolean=} data.isCallDone isCallDone
     * @param {number=} data.boardColumnId boardColumnId
     * @param {object=} data.boardColumn boardColumn
     * @param {object[]=} data.boardComments boardComments
     * @param {string=} data.interviewStartDate interviewStartDate
     * @param {string=} data.interviewEndDate interviewEndDate
     * @param {string=} data.interviewClientStartDate interviewClientStartDate
     * @param {string=} data.interviewClientEndDate interviewClientEndDate
     * @param {object=} data.fileCv fileCv
     * @param {number=} data.candidateId candidateId
     * @param {object=} data.candidate candidate
     * @param {object[]=} data.files files
     * @param {object=} data.interview interview
     * @param {object[]=} data.boardHistorics boardHistorics
     * @param {boolean=} data.isUnderProposal isUnderProposal
     * @param {boolean=} data.isInterviewCancel isInterviewCancel
     * @param {boolean=} data.isInterviewClientCancel isInterviewClientCancel
     * @param {number=} data.huntProvenanceId huntProvenanceId
     * @param {object=} data.huntProvenance huntProvenance
     * @param {string=} data.currentSalary currentSalary
     * @param {string=} data.desiredSalary desiredSalary
     * @param {boolean=} data.isDeleted isDeleted
     * @param {boolean=} data.isCancelled isCancelled
     */
    constructor({
        huntId = 0,
        missionId = 0,
        mission,
        company = '',
        zipCode = '',
        cityId,
        city = {},
        departmentId,
        department = {},
        landlinePhone = '',
        comment = '',
        isRejected = false,
        isCancelled = false,
        isFavorite = false,
        isCallDone = false,
        boardComments = [],
        boardColumnId = 0,
        boardColumn = {},
        interviewStartDate,
        interviewEndDate,
        interviewClientStartDate,
        interviewClientEndDate,
        fileCv = {},
        candidateId = 0,
        candidate,
        files = [],
        interview = {},
        boardHistorics = [],
        isUnderProposal = false,
        isInterviewCancel = false,
        isInterviewClientCancel = false,
        huntProvenanceId,
        huntProvenance = {},
        currentSalary = '',
        desiredSalary = '',
        isDeleted = false,
        ...data
    } = {}) {
        super(data)
        this.huntId = huntId
        this.missionId = missionId
        this.mission = mission ? new Mission(mission) : undefined
        this.company = company
        this.zipCode = zipCode
        this.cityId = cityId
        this.city = city
        this.departmentId = departmentId
        this.department = department
        this.landlinePhone = landlinePhone
        this.comment = comment
        this.isRejected = isRejected
        this.isCancelled = isCancelled
        this.isFavorite = isFavorite
        this.isCallDone = isCallDone
        this.boardColumnId = boardColumnId
        this.boardColumn = boardColumn
        this.boardComments = (boardComments?.map(x => new BoardComment(x)) ?? [])
            .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        this.interviewStartDate = interviewStartDate ? new Date(interviewStartDate) : undefined
        this.interviewEndDate = interviewEndDate ? new Date(interviewEndDate) : undefined
        this.interviewClientStartDate = interviewClientStartDate ? new Date(interviewClientStartDate) : undefined
        this.interviewClientEndDate = interviewClientEndDate ? new Date(interviewClientEndDate) : undefined
        this.fileCv = fileCv ? new FileCv(fileCv) : undefined
        this.candidateId = candidateId
        this.candidate = new Candidate(candidate || {})
        this.files = files?.map(x => new File(x)) ?? []
        this.interview = new Interview(interview || {})
        this.boardHistorics = boardHistorics?.map(x => new BoardColumn(x)) ?? []
        this.isUnderProposal = isUnderProposal
        this.isInterviewCancel = isInterviewCancel
        this.isInterviewClientCancel = isInterviewClientCancel
        this.huntProvenanceId = huntProvenanceId
        this.huntProvenance = huntProvenance

        this.currentSalary = currentSalary
        this.desiredSalary = desiredSalary
        this.isDeleted = isDeleted

        this.type = EBoardCardType.HUNT
    }
}

/**
 * Hunt Object used to bind error message
 */
export class ErrorHunt extends ErrorBase {
    constructor() {
        super()
        this.huntId = ''
        this.missionId = ''
        this.company = ''
        this.zipCode = ''
        this.cityId = ''
        this.city = ''
        this.departmentId = ''
        this.landlinePhone = ''
        this.comment = ''
        this.isRejected = ''
        this.isCancelled = ''
        this.isFavorite = ''
        this.isCallDone = ''
        this.firstname = ''
        this.lastname = ''
        this.boardColumnId = ''
        this.interviewStartDate = ''
        this.interviewEndDate = ''
        this.interviewClientStartDate = ''
        this.interviewClientEndDate = ''
        this.fileCv = ''
        this.candidateId = ''
        this.candidate = {}
        this.interview = ''
        this.isUnderProposal = ''
        this.isInterviewCancel = ''
        this.isInterviewClientCancel = ''
        this.huntProvenance = ''
        this.huntProvenanceId = ''
        this.currentSalary = ''
        this.desiredSalary = ''
        this.isDeleted = ''
    }
}
