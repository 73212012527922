import React, { PureComponent } from 'react'
import {
    Label, MessageBarType, TextField, ICommandBarItemProps, Toggle, TooltipHost, DirectionalHint, TooltipDelay, Icon,
    Text,
} from '@fluentui/react'
import history from 'helpers/history'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import User, { ErrorUser } from 'requests/objects/user'
import EProfile from 'types/profile'
import setPageTitle from 'helpers/methods/pageTitle'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdUsers extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.userId,
            /** @type {User} Element find from API */
            item: new User(),
            /** @type {User} Initial element fond from API. Used to refresh */
            iniItem: new User(),
            /** @type {ErrorUser} Errors */
            errorField: new ErrorUser(),
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { match, setCommand } = this.props

        if (match?.params?.userId !== prevProps?.match?.params?.userId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.usersHandlerRemoveById?.cancel()
        this.usersHandlerGetById?.cancel()
        this.usersHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const mainTitle = `Utilisateur ${match?.params?.userId ? [item.lastname, item.firstname].filter(x => x).join(' ').trim() || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie utilisateur', key: 'utilisateur' },
            { text: 'Tous les utilisateurs', key: 'all-utilisateur', href: '/utilisateurs' },
            {
                text: mainTitle,
                key: 'one-utilisateur',
                isCurrentItem: true,
            },
        ])
        setTitle('Utilisateurs')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, usersHandler, setMessageBar, removeParam,
        } = this.props

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
            },
        ]

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorUser(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.userId,
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: "Supprimer l'utilisateur",
                        subTitle: "Êtes-vous certain de vouloir supprimer l'utilisateur ? Cette action est définitive.",
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.usersHandlerRemoveById = usersHandler.removeById(match?.params?.userId)
                                    await this.usersHandlerRemoveById.fetch()
                                    history.push('/utilisateurs')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    });
                                    ['consultants', 'managers', 'operationsManagers'].forEach(key => removeParam({
                                        // eslint-disable-next-line object-shorthand
                                        key: /** @type {any} */(key),
                                        value: { key: Number.parseInt(match?.params?.userId, 10) },
                                    }))
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.userId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, usersHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.userId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.user) // If object came from history push with a create
                    this.setState({
                        item: location.state?.user,
                        iniItem: location.state?.user,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.usersHandlerGetById = usersHandler.getById(match?.params?.userId)
                        const user = await this.usersHandlerGetById.fetch()
                        this.setState({
                            item: user,
                            iniItem: user,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new User(),
                    iniItem: new User(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, usersHandler, match, setMessageBar, addParam, editParam,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.usersHandlerUpsert = usersHandler.upsert(item, match?.params?.userId)
                const user = await this.usersHandlerUpsert.fetch()

                this.setState({
                    item: user,
                    iniItem: user,
                    status: Status.RESOLVED,
                    errorField: new ErrorUser(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                const key = (() => {
                    switch (user.profileId) {
                        case EProfile.Consultant:
                            return 'consultants'
                        case EProfile.OperationsManager:
                            return 'operationsManagers'
                        default:
                            return null
                    }
                })()

                if (!match?.params?.userId)
                    if (key) {
                        addParam({
                            key,
                            value: { key: user.userId, text: `${user.lastname} ${user.firstname}` },
                        })
                        history.replace(`/utilisateurs/${user.userId}`)
                    } else {
                        editParam({
                            key,
                            value: { key: user.userId, text: `${user.lastname} ${user.firstname}` },
                        })
                    }
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField,
        } = this.state
        const { param } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this.upsert()
                }}
            >
                <Card
                    title="Champs"
                    iconName="PageData"
                >
                    <Columns>
                        <Columns.Column size="one-quarter">

                            <TextField
                                label="Email"
                                value={item.email}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, email: newVal } })}
                                errorMessage={errorField.email}
                                required
                                type="email"
                                // eslint-disable-next-line react/no-unstable-nested-components
                                onRenderInput={(props, defaultRender) => (
                                    props.readOnly && item.email ? (
                                        <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                            <a
                                                href={`mailto:${item.email}`}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                {item.email}
                                            </a>
                                        </Text>
                                    ) : (
                                        defaultRender(props)
                                    )
                                )}
                            />

                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Prénom"
                                value={item.firstname}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, firstname: newVal?.toUpperCase() } })}
                                errorMessage={errorField.firstname}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Nom"
                                value={item.lastname}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, lastname: newVal?.toUpperCase() } })}
                                errorMessage={errorField.lastname}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Mot de passe"
                                value={item.password}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, password: newVal } })}
                                errorMessage={errorField.password}
                                required={!item.userId}
                                canRevealPassword={!isReadOnly}
                                type="password"
                                // eslint-disable-next-line react/no-unstable-nested-components
                                onRenderLabel={(props, defaultRender) => (
                                    <div style={{ display: 'flex' }}>
                                        {defaultRender(props)}
                                        <TooltipHost
                                            content="Si laissé vide alors le mot de passe de ne seras pas modifié sinon celui-ci sera modifié."
                                            directionalHint={DirectionalHint.topCenter}
                                            delay={TooltipDelay.zero}
                                            styles={{
                                                root: {
                                                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                                                },
                                            }}
                                        >
                                            <Icon
                                                iconName="Info"
                                                styles={{ root: { padding: '3px', cursor: 'help' } }}
                                            />
                                        </TooltipHost>
                                    </div>
                                )}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Code"
                                value={item.code}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, code: newVal?.toUpperCase() } })}
                                errorMessage={errorField.code}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Bureau"
                                value={item.desk}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, desk: newVal?.toUpperCase() } })}
                                errorMessage={errorField.desk}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <Label
                                required
                                htmlFor="hasLeft"
                            >
                                Out
                            </Label>
                            <Toggle
                                id="hasLeft"
                                onText="Oui"
                                offText="Non"
                                checked={item.hasLeft}
                                onChange={(_ev, checked) => this.setState({ item: { ...item, hasLeft: checked } })}
                                disabled={isReadOnly}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Profil"
                                readOnly={isReadOnly}
                                options={param.profiles}
                                selectedKey={item.profileId}
                                onChange={(_ev, option) => this.setState({
                                    item: {
                                        ...item,
                                        profileId: option.key,
                                        hasOpManAccessBoardReception: false,
                                    },
                                })}
                                errorMessage={errorField.profileId}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Manager"
                                readOnly={isReadOnly}
                                options={param.administrators}
                                selectedKey={item.userManagerId}
                                onChange={(_ev, option) => this.setState({ item: { ...item, userManagerId: option.key } })}
                                errorMessage={errorField.userManagerId}
                            />
                        </Columns.Column>
                        {item.profileId === EProfile.OperationsManager && (
                            <Columns.Column size="one-quarter">
                                <Label
                                    required
                                    htmlFor="hasOpManAccessBoardReception"
                                >
                                    À accès "Réception retour annonces"
                                </Label>
                                <Toggle
                                    id="hasOpManAccessBoardReception"
                                    onText="Oui"
                                    offText="Non"
                                    checked={item.hasOpManAccessBoardReception}
                                    onChange={(_ev, checked) => this.setState({ item: { ...item, hasOpManAccessBoardReception: checked } })}
                                    disabled={isReadOnly}
                                />
                            </Columns.Column>
                        )}
                    </Columns>
                </Card>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdUsers.prototype.getUpdatedList = getUpdatedList
IdUsers.prototype.isValidDate = isValidDate
