import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, PrimaryButton, TextField,
} from '@fluentui/react'
import Candidacy, { ErrorCandidacy } from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
import Hunt, { ErrorHunt } from 'requests/objects/hunt'
import HuntsHandler from 'requests/handlers/huntsHandler'

/**
 * BoardCancelledModal
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {(item: Candidacy | Hunt) => void} props.onUpdate onUpdate
 * @returns {JSX.Element} Returns
 */
export default function BoardCancelledModal({
    item: itemProps, handler, isVisible, setIsVisible, onUpdate,
}) {
    const [item, setItem] = useState(itemProps || new Candidacy())
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(/** @type {ErrorHunt | ErrorCandidacy} */({}))

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerUpdateBoardItem = useRef(null)

    const update = useCallback(
        /**
         * @param {boolean} isCancelled isCancelled
         */
        async isCancelled => {
            try {
                setStatus(Status.PENDING)
                handlerUpdateBoardItem.current = handler.patchCancelled(
                /** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId,
                    { isCancelled, comment: item.comment },
                )
                const newItem = await handlerUpdateBoardItem.current.fetch()
                onUpdate(newItem)
                setStatus(Status.RESOLVED)
                setIsVisible(false)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case NotImplementedError:
                        break
                    case InvalidEntityError:
                        setErrorField(/** @type {InvalidEntityError<ErrorHunt | ErrorCandidacy>} */(error).errorField)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setStatus(Status.REJECTED)
                        break
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setStatus(Status.REJECTED)
                        break
                }
            }
        }, [item, handler, setIsVisible, onUpdate],
    )

    // Update local item on props change
    useEffect(() => {
        if (isVisible)
            setItem(itemProps || new Candidacy())
    }, [isVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpdateBoardItem?.current?.cancel()
    }, [])

    if (item.isCancelled)
        return (
            <Dialog
                hidden={!isVisible}
                onDismiss={() => setIsVisible(false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Dé-annuler le candidat',
                    subText: 'Voulez-vous annuler le candidat ?',
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
            >
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        update(false)
                    }}
                >
                    <DialogFooter>
                        <DefaultButton
                            onClick={() => setIsVisible(false)}
                            text="Non"
                            disabled={status === Status.PENDING}
                        />
                        <PrimaryButton
                            type="submit"
                            text="Oui"
                            disabled={status === Status.PENDING}
                        />
                    </DialogFooter>
                </form>
            </Dialog>
        )

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Annuler le candidat',
                subText: 'Voulez-vous annuler le candidat ?',
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
            minWidth="555px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    update(true)
                }}
            >
                <TextField
                    label="Commentaire"
                    value={item.comment || ''}
                    readOnly={status === Status.PENDING}
                    borderless={status === Status.PENDING}
                    onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, comment: newVal?.toUpperCase() }))}
                    errorMessage={errorField.comment}
                    multiline
                    autoAdjustHeight
                    required
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text="Non"
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text="Oui"
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
