import React, { PureComponent } from 'react'
import {
    ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, buildColumns, Text, IColumn, TextField, Label, DefaultButton,
} from '@fluentui/react'
import history from 'helpers/history'
import { getUpdatedList, handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Mission from 'requests/objects/mission'
import { Link } from 'react-router-dom'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/missions/index.module.scss'
import { NEW_PATH } from 'types/others'
import missionColumns from 'types/missions/columns'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'
import EProfile from 'types/profile'

/** @debug {AppProps} */

/**
 * @typedef {object} SearchParamsType
 * @property {string} ref ref
 * @property {number} divisionId divisionId
 * @property {number[]} subDivisionIds subDivisionIds
 * @property {number[]} metierIds metierIds
 * @property {number} clientId clientId
 * @property {number} contactId contactId
 * @property {number} missionStatusId missionStatusId
 * @property {number} userCollaboratorId userCollaboratorId
 */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexMissions extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {Mission[]} Items found in API */
            items: [],
            /** @type {Mission[]} Initial items found in API */
            iniItems: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                ref: null,
                divisionId: null,
                subDivisionIds: [],
                metierIds: [],
                clientId: null,
                contactId: null,
                missionStatusId: null,
                userCollaboratorId: null,
            },
        }

        this.submitInput = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }
        // this.init()

        // this.setupBreadcrumb()

        // this.setupCommandBar()

        // this.setState({
        //     columns: this.buildColumns(),
        // })

        if (prevState.searchParams?.divisionId !== searchParams?.divisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    subDivisionIds: [],
                    metierIds: [],
                },
            }))

        if (prevState.searchParams?.subDivisionIds.length !== searchParams?.subDivisionIds.length
            || !prevState.searchParams?.subDivisionIds.every((v, i) => v === searchParams?.subDivisionIds[i])
        )
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    metierIds: [],
                },
            }))
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.missionsHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, setTitle } = this.props

        setBreadcrumb([
            { text: 'Saisie mission', key: 'mission' },
            { text: 'Toutes les missions', key: 'all-missions', isCurrentItem: true },
        ])
        setTitle('Missions')
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand, me } = this.props

        setCommand(
            [
                {
                    key: 'new',
                    text: 'Nouveau',
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/missions/${NEW_PATH}`),
                    disabled: me.profileId === EProfile.OperationsManager,
                },
            ],
        )
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    ref: queryParams.get('ref') || null,
                    divisionId: queryParams.get('divisionId') ? +queryParams.get('divisionId') : null,
                    subDivisionIds: queryParams.getAll('subDivisionIds') ? queryParams.getAll('subDivisionIds').map(x => +x) : [],
                    metierIds: queryParams.getAll('metierIds') ? queryParams.getAll('metierIds').map(x => +x) : [],
                    // clientId: queryParams.get('clientId') ? +queryParams.get('clientId') : null,
                    clientId: null,
                    // contactId: queryParams.get('contactId') ? +queryParams.get('contactId') : null,
                    contactId: null,
                    missionStatusId: queryParams.get('missionStatusId') ? +queryParams.get('missionStatusId') : null,
                    userCollaboratorId: queryParams.get('userCollaboratorId') ? +queryParams.get('userCollaboratorId') : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }

        this.setState({ columns: this.buildColumns() })
    }

    /**
     * Init <DetailList>
     * @returns {IColumn[]} Columns
     */
    buildColumns() {
        const cols = missionColumns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this.onColumnClick.bind(this, {
                colName: 'columns', dataName: ['items'], source: 'state', isFlatten: true,
            }),
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case 'Entreprise':
                    case 'Intitulé de poste':
                    case 'Date de création':
                    case 'Référence':
                    case 'Consultant':
                    case 'Chargé de recrutement':
                    case 'Client':
                    case 'Contact client':
                    case 'Localisation':
                    case 'Statut':
                        return 133
                    default:
                        return (column.name?.length ?? 1) * 12
                }
            })()
            column.minWidth = (column.name?.length ?? 1) * 5
            // eslint-disable-next-line max-len
            column.onRender = missionColumns.find(x => x.name === column.name)?.onRender ?? this.handleRenderColumn(missionColumns, column.name, column)
        }

        return columns
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { missionsHandler, setBreadcrumb } = this.props
            const { searchParams } = this.state
            setBreadcrumb([
                { text: 'Saisie mission', key: 'mission' },
                { text: 'Toutes les missions', key: 'all-missions', isCurrentItem: true },
            ])
            try {
                this.missionsHandlerGetAll = missionsHandler.getAll(searchParams)
                const missions = await this.missionsHandlerGetAll.fetch()
                this.setState({
                    items: missions.map(x => this.flattenObj(x)),
                    iniItems: missions,
                    status: Status.RESOLVED,
                })
                setBreadcrumb([
                    { text: 'Saisie mission', key: 'mission' },
                    { text: `Toutes les missions (${missions.length || 0})`, key: 'all-missions', isCurrentItem: true },
                ])
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            iniItems: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            columns, status, items, searchParams,
            iniItems, // eslint-disable-line no-unused-vars
        } = this.state
        const { param, searchFiltersHandler } = this.props

        return (
            <main className={styles.index}>

                <form
                    className={styles['index-search-filters']}
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.search()
                    }}
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Référence"
                                disabled={status === Status.PENDING}
                                value={searchParams.ref || ''}
                                onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, ref: newVal } })}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Division"
                                options={param.divisions}
                                selectedKey={searchParams.divisionId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, divisionId: option.key } })}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Sous-famille(s)"
                                options={param.subDivisions.filter(x => !searchParams.divisionId || x.divisionId === searchParams.divisionId)}
                                selectedKey={searchParams.subDivisionIds}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({
                                    searchParams: { ...searchParams, subDivisionIds: this.getUpdatedList(searchParams.subDivisionIds, option) },
                                })}
                                multiSelect
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Metier(s)"
                                options={param.metiers.filter(x => !searchParams.subDivisionIds?.length
                                    || searchParams.subDivisionIds.includes(x.subDivisionId))}
                                selectedKey={searchParams.metierIds}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({
                                    searchParams: { ...searchParams, metierIds: this.getUpdatedList(searchParams.metierIds, option) },
                                })}
                                multiSelect
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualComboboxAjax
                                label="Client"
                                minChar={1}
                                disabled={status === Status.PENDING}
                                selectedKey={searchParams.clientId}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, clientId: option.key } })}
                                functionName="searchClients"
                                handler={searchFiltersHandler}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualComboboxAjax
                                label="Contact"
                                minChar={1}
                                disabled={status === Status.PENDING}
                                selectedKey={searchParams.contactId}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, contactId: option.key } })}
                                functionName="searchContacts"
                                handler={searchFiltersHandler}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Statut"
                                options={param.missionStatus}
                                selectedKey={searchParams.missionStatusId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, missionStatusId: option.key } })}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Collaborateur"
                                options={param.consultants}
                                selectedKey={searchParams.userCollaboratorId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, userCollaboratorId: option.key } })}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <Label className="is-hidden-mobile">&nbsp;</Label>
                            <DefaultButton
                                text="Rechercher"
                                primary
                                split
                                disabled={status === Status.PENDING}
                                onClick={() => this.submitInput.current.click()}
                                iconProps={{ iconName: 'Search' }}
                                menuProps={{
                                    items: [
                                        {
                                            key: 'Search',
                                            text: 'Rechercher',
                                            iconProps: { iconName: 'Search' },
                                            onClick: () => this.submitInput.current.click(),
                                        },
                                        {
                                            key: 'Reset',
                                            text: 'Réinitialiser',
                                            iconProps: { iconName: 'Filter' },
                                            onClick: () => this.setState({
                                                /** @type {SearchParamsType} */
                                                searchParams: {
                                                    ref: null,
                                                    divisionId: null,
                                                    subDivisionIds: [],
                                                    metierIds: [],
                                                    clientId: null,
                                                    contactId: null,
                                                    missionStatusId: null,
                                                    userCollaboratorId: null,
                                                },
                                                items: [],
                                                iniItems: [],
                                            }, () => this.setState({ columns: this.buildColumns() })),
                                        },
                                    ],
                                }}
                            />
                        </Columns.Column>
                    </Columns>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (
                            <Link
                                to={{
                                    pathname: `/missions/${props.item?.missionId}`,
                                    // state: {
                                    //     mission: iniItems.find(x => x.missionId === props.item?.missionId),
                                    // },
                                }}
                                onClick={ev => {
                                    // eslint-disable-next-line prefer-destructuring
                                    const target = /** @type {HTMLElement} */(ev.target)
                                    if (target.closest('button') || target.nodeName.toLowerCase() === 'button')
                                        ev.preventDefault()
                                }}
                            >
                                {defaultRender(props)}
                            </Link>
                        )}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
                    {!items?.length && [Status.IDLE].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Veuillez lancer une recherche</Text>}
                </Card>
            </main>
        )
    }
}

IndexMissions.prototype.onColumnClick = onColumnClick
IndexMissions.prototype.flattenObj = flattenObj
IndexMissions.prototype.handleRenderColumn = handleRenderColumn
IndexMissions.prototype.getUpdatedList = getUpdatedList
