import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField, ICommandBarItemProps, Label, Toggle,
} from '@fluentui/react'
import history from 'helpers/history'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import EventClient, { ErrorEventClient } from 'requests/objects/eventClient'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import Time from 'helpers/methods/time'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'
import setPageTitle from 'helpers/methods/pageTitle'
import EventFileInput from 'components/pages/clients/[id]/index/contacts/[id]/evenements/eventFileInput'
import FileInput from 'components/inputs/fileInput'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdEventClients extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.eventClientId,
            /** @type {EventClient} Element find from API */
            item: new EventClient(),
            /** @type {EventClient} Initial element fond from API. Used to refresh */
            iniItem: new EventClient(),
            /** @type {ErrorEventClient} Errors */
            errorField: new ErrorEventClient(),
            /** @type {globalThis.File} tempFile */
            tempFile: null,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { match, setCommand } = this.props

        if (match?.params?.eventClientId !== prevProps?.match?.params?.eventClientId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.eventClientHandlerRemoveById?.cancel()
        this.eventClientHandlerGetById?.cancel()
        this.eventClientHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const mainTitle = `Évènement ${match?.params?.eventClientId ? item.eventType?.name || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie client', key: 'client' },
            { text: 'Tous les clients', key: 'all-client', href: '/clients' },
            {
                text: `Client ${match?.params?.clientId ? item.contact?.client?.name || '' : 'Nouveau'}`,
                key: 'one-client',
                href: `/clients/${match?.params?.clientId}`,
            },
            {
                // eslint-disable-next-line max-len
                text: `Contact ${match?.params?.contactId ? [item.contact?.lastname, item.contact?.firstname].filter(x => x).join(' ').trim() || '' : 'Nouveau'}`,
                key: 'one-contact',
                href: `/clients/${match?.params?.clientId}/contacts/${match?.params?.contactId}`,
            },
            {
                text: mainTitle,
                key: 'one-event-client',
                isCurrentItem: true,
            },
        ])
        setTitle('Clients')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, contactsHandler, setMessageBar,
        } = this.props

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
            },
        ]

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorEventClient(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.eventClientId,
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Supprimer l\'évenement',
                        subTitle: 'Êtes-vous certain de vouloir supprimer l\'évenement ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.eventClientHandlerRemoveById = contactsHandler.removeEventClientById(
                                        match?.params?.contactId,
                                        match?.params?.eventClientId,
                                    )
                                    await this.eventClientHandlerRemoveById.fetch()
                                    // eslint-disable-next-line max-len
                                    history.push(`/clients/${match?.params?.clientId}/contacts/${match?.params?.contactId}/evenements/${match?.params?.eventClientId}`)
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.eventClientId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, contactsHandler, setMessageBar, me,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.eventClientId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.eventClient) // If object came from history push with a create
                    this.setState({
                        item: location.state?.eventClient,
                        iniItem: location.state?.eventClient,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.eventClientHandlerGetById = contactsHandler.getEventClientById(match?.params?.contactId, match?.params?.eventClientId)
                        const eventClient = await this.eventClientHandlerGetById.fetch()
                        this.setState({
                            item: eventClient,
                            iniItem: eventClient,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new EventClient({
                        contactId: Number.parseInt(match.params?.contactId, 10),
                        date: Time(new Date()).getLocaleDateString(),
                        userCollaboratorId: me.userId,
                        todoDate: Time(new Date()).getLocaleDateString(),
                    }),
                    iniItem: new EventClient({
                        contactId: Number.parseInt(match.params?.contactId, 10),
                        date: Time(new Date()).getLocaleDateString(),
                        userCollaboratorId: me.userId,
                        todoDate: Time(new Date()).getLocaleDateString(),
                    }),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, contactsHandler, match, setMessageBar,
        } = this.props
        const { item, tempFile } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.eventClientHandlerUpsert = contactsHandler.upsertEventClient(match?.params?.contactId, match?.params?.eventClientId, item)
                const eventClient = await this.eventClientHandlerUpsert.fetch()

                if (tempFile)
                    try {
                        setMessageBar({ isDisplayed: false })
                        this.handlerUploadFile = contactsHandler.uploadEventClientFile(
                            eventClient.contactId,
                            eventClient.eventClientId,
                            tempFile,
                        )
                        eventClient.file = await this.handlerUploadFile.fetch()
                        setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté" })
                    } catch (error) {
                        setMessageBar({
                            isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté mais l'ajout du fichier a échoué",
                        })
                    }

                this.setState({
                    item: eventClient,
                    iniItem: eventClient,
                    status: Status.RESOLVED,
                    errorField: new ErrorEventClient(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.eventClientId)
                    history.replace(`/clients/${match?.params?.clientId}/contacts/${match?.params?.contactId}/evenements/${eventClient.eventClientId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorEventClient>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, tempFile,
        } = this.state
        const { param, searchFiltersHandler, contactsHandler } = this.props

        if (status === Status.PENDING)
            return <Loader />
        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this.upsert()
                }}
            >
                <Card
                    title="Champs"
                    iconName="PageData"
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Type d'évenement"
                                readOnly={isReadOnly}
                                options={param.clientEventTypes.filter(option => ![34, 30, 32].includes(option.key))}
                                selectedKey={item.eventTypeId}
                                onChange={(_ev, option) => this.setState({ item: { ...item, eventTypeId: option.key } })}
                                errorMessage={errorField.eventTypeId}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualComboboxAjax
                                label="Mission"
                                minChar={1}
                                defaultValue={item.mission ? item.mission?.ref : ''}
                                readOnly={isReadOnly}
                                selectedKey={item.missionId}
                                onChange={(_ev, option) => this.setState({ item: { ...item, missionId: option.key } })}
                                functionName="searchMissions"
                                handler={searchFiltersHandler}
                                errorMessage={errorField.missionId}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Date évènement"
                                value={item.todoDate ? Time(item.todoDate).getLocaleDateString() : ''}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({
                                    item: { ...item, todoDate: this.isValidDate(new Date(newVal)) ? new Date(newVal) : '' },
                                })}
                                errorMessage={errorField.todoDate}
                                type="date"
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <Label
                                htmlFor="hasTodo"
                            >
                                A TODO
                            </Label>
                            <Toggle
                                id="hasTodo"
                                onText="Oui"
                                offText="Non"
                                checked={item.hasTodo}
                                onChange={(_ev, checked) => this.setState({ item: { ...item, hasTodo: checked } })}
                                disabled={isReadOnly}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size="half">
                            <TextField
                                label="Commentaire"
                                value={item.comment}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, comment: newVal?.toUpperCase() } })}
                                errorMessage={errorField.comment}
                                multiline
                                autoAdjustHeight
                            />
                        </Columns.Column>
                        {/* ENVOI CONTRAT COLLABORATION */}
                        {item.eventTypeId === 35 && (
                            <Columns.Column size="one-quarter">
                                {item.eventClientId
                                    ? (
                                        <EventFileInput
                                            handler={contactsHandler}
                                            contactId={item.contactId}
                                            eventClientId={item.eventClientId}
                                            item={item}
                                            isReadOnly={isReadOnly}
                                            updateItem={newFile => this.setState({ item: { ...item, file: newFile } })}
                                        />
                                    )
                                    : (
                                        <FileInput
                                            label="Fichier"
                                            labelUpload="Télécharger"
                                            isReadOnly={false}
                                            isDisabled={status === Status.PENDING}
                                            fileName={tempFile?.name}
                                            tooltipContent=""
                                            onDownload={() => null}
                                            onUpload={file => Promise.resolve(this.setState({ tempFile: file }))}
                                            onDelete={() => Promise.resolve(this.setState({ tempFile: null }))}
                                        />
                                    )}
                            </Columns.Column>
                        )}
                    </Columns>
                </Card>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdEventClients.prototype.getUpdatedList = getUpdatedList
IdEventClients.prototype.isValidDate = isValidDate
