import React from 'react'
import Card from 'components/containers/card'
import { Columns } from 'react-bulma-components'
import DocumentInput from 'components/pages/missions/[id]/documentInput'
import Mission from 'requests/objects/mission'
import MissionsHandler from 'requests/handlers/missionsHandler'

/**
 * Pivot docs
 * @param {object} props Props
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {Mission} props.item item
 * @param {MissionsHandler} props.missionsHandler missionsHandler
 * @param {(item: Partial<Mission>, isIniItemToo?: boolean) => void} props.onChange onChange
 * @returns {JSX.Element} Returns
 */
export default function PivotDocuments({
    isReadOnly, item, missionsHandler, onChange,
}) {
    return (
        <Card
            title="Documents relatifs à la mission"
            iconName="DocumentSet"
        >
            <Columns>
                <Columns.Column size="one-quarter">
                    <DocumentInput
                        label="Contrat signé"
                        fieldKey="contractFile"
                        labelUpload="Télécharger le contrat"
                        isReadOnly={isReadOnly}
                        handler={missionsHandler}
                        updateItem={file => onChange({ contractFile: file }, true)}
                        mission={item}
                    />
                </Columns.Column>
                <Columns.Column size="one-quarter">
                    <DocumentInput
                        label="Fiche de recherche"
                        fieldKey="sheetFile"
                        labelUpload="Télécharger la fiche"
                        isReadOnly={isReadOnly}
                        handler={missionsHandler}
                        updateItem={file => onChange({ sheetFile: file }, true)}
                        mission={item}
                    />
                </Columns.Column>
                <Columns.Column size="one-quarter">
                    <DocumentInput
                        label="Annonce"
                        fieldKey="advertFile"
                        labelUpload="Télécharger l'annonce"
                        isReadOnly={isReadOnly}
                        handler={missionsHandler}
                        updateItem={file => onChange({ advertFile: file }, true)}
                        mission={item}
                    />
                </Columns.Column>
            </Columns>
        </Card>
    )
}
